import {
  FETCH_FEATURE_FLAGS_BEGIN,
  FETCH_FEATURE_FLAGS_SUCCESS,
  FETCH_FEATURE_FLAGS_FAILURE,
} from '../actions/featureFlags';

export const initialState = {
  error: null,
  isFetching: false,
};

const featureFlags = (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_FEATURE_FLAGS_BEGIN:
      return {
        ...state,
        error: null,
        isFetching: true,
      };
    case FETCH_FEATURE_FLAGS_SUCCESS:
      return {
        ...state,
        available: true,
        error: null,
        isFetching: false,
      };
    case FETCH_FEATURE_FLAGS_FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default featureFlags;
