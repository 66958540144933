import React, { useState } from 'react';
import DropdownButton from '../DropdownButton';
import DropdownOption from '../DropdownOption';
import EditEmailModal from './EditEmailModal';

const setAsPrimaryOption = (isVerified, isPrimary, handlePrimary) => {
  return isVerified && !isPrimary ? (
    <DropdownOption
      text="Set as primary"
      key="setAsPrimary"
      onClick={handlePrimary}
    />
  ) : null;
};

const editEmailOption = (isVerified, openEditEmailModal) => {
  return isVerified ? (
    <DropdownOption text="Edit" key="editEmail" onClick={openEditEmailModal} />
  ) : null;
};

const resendConfirmationOption = (isVerified, handleResend) => {
  return !isVerified ? (
    <DropdownOption
      text="Resend confirmation"
      key="resendConfirmation"
      onClick={handleResend}
    />
  ) : null;
};

const deleteOption = (isPrimary, handleDelete) => {
  return !isPrimary ? (
    <DropdownOption text="Delete" key="deleteEmail" onClick={handleDelete} />
  ) : null;
};

const UpdateEmailButton = ({
  email,
  handlePrimary,
  handleResend,
  handleDelete,
  isFetching,
}) => {
  const [isEditEmailModalOpen, setIsEditEmailModalOpen] = useState(false);

  const openEditEmailModal = () => {
    setIsEditEmailModalOpen(true);
  };

  return (
    <>
      <DropdownButton
        ariaLabel="Update email options"
        isFetching={isFetching}
        dropdownOptions={[
          setAsPrimaryOption(email.isVerified, email.isPrimary, handlePrimary),
          editEmailOption(email.isVerified, openEditEmailModal),
          resendConfirmationOption(email.isVerified, handleResend),
          deleteOption(email.isPrimary, handleDelete),
        ]}
      />
      {isEditEmailModalOpen && (
        <EditEmailModal
          email={email}
          closeModal={() => setIsEditEmailModalOpen(false)}
          isOpen={isEditEmailModalOpen}
        />
      )}
    </>
  );
};

export default UpdateEmailButton;
